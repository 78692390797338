$oss_host: 'https://oss.dzans.cn/static';

@mixin iconMixin() {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    flex-shrink: 0;
}

@keyframes loading {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
}
.icon-loading {
    width: 14px;
    height: 14px;
    background-image: url($oss_host + '/icon/icon-loading.png');
    @include iconMixin();
    animation-name: loading;
    animation-duration: 800ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    margin-right: 3px;
}

.icon-edit, .icon-refresh, .icon-push {
    width: 16px;
    height: 16px;
    margin-right: 3px;
    @include iconMixin();
}
.icon-edit { background-image: url($oss_host + '/icon/icon-edit1.png'); }
.icon-refresh { background-image: url($oss_host + '/icon/icon-refresh2.png'); }
.icon-push { background-image: url($oss_host + '/icon/icon-push.png'); }

.ai-wrapper {
    height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    background-image: url($oss_host + '/gongtou/ai-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0;
    .content {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .header {
        padding: 6px 15px 16px;
        .logo {
            display: block;
            width: 80px;
            height: 80px;
            background-image: url($oss_host + '/gongtou/ai-sprite.png?v=1');
            background-repeat: no-repeat;
            background-size: 520PX 246PX;
            background-position: -88px -121px;
            &.coupon {
                background-position: 0 -121px;
            }
        }
        .inner {
            position: relative;
            z-index: 1;
            margin-top: -30px;
            padding: 10px;
            background-color: #F1F1F5;
            border-radius: 10px;
            color: #1B1A1E;
            font-size: 14px;
            line-height: 20px;
        }
        .title {
            color: #1B1A1E;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.4;
            margin-bottom: 10px;
        }
    }
    .chats {
        padding: 0 15px;
        .item-bubble {
            display: flex;
            padding-bottom: 16px;
            .item-inner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1;
                padding-left: 5px;
                padding-right: 37px;
            }
            .item-avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
            .item-input {
                width: auto;
                color: #1B1A1E;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                padding: 8px 10px;
                background-color: #fff;
                border-radius: 0 17px 17px 17px;
            }
            &.isMe {
                flex-direction: row-reverse;
                .item-inner {
                    padding-left: 37px;
                    padding-right: 5px;
                    align-items: flex-end;
                }
                .item-input {
                    color: #fff;
                    background: linear-gradient(135deg, #5B3CF9 7.59%, #9334EA 48.95%, #F13DB7 92.07%);
                    border-radius: 17px 0 17px 17px;
                }
            }
        }
        .ai-bubble {
            padding-bottom: 16px;
            &-title {
                margin-bottom: 10px;
                .title {
                    color: #63626C;
                    font-size: 12px;
                    line-height: 17px;
                    padding-right: 8px;
                }
                .bubble {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 24px;
                    color: #63626C;
                    font-size: 12px;
                    padding: 0 6px;
                    background: rgba(102,91,212,0.1);
                    border-radius: 24px;
                }
            }
            &-inner {
                color: #1B1A1E;
                font-size: 14px;
                line-height: 20px;
                padding: 10px;
                background-color: #F1F1F5;
                border-radius: 10px;
            }
            &-footer {
                margin-top: 10px;
            }
            &-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                color: #1B1A1E;
                font-size: 14px;
                line-height: 20px;
                border-radius: 32px;
                padding-left: 8px;
                padding-right: 10px;
                background-color: #F1F1F5;
                margin-right: 7px;
                &.active {
                    color: #fff;
                    background: linear-gradient(135deg, #5B3CF9, #9334EA, #F13DB7);
                }
            }
            &-img {
                margin-bottom: 10px;
                width: 160px;
                border-radius: 5px;
            }
            &-video {
                width: 100%;
                height: 160px;
                border-radius: 5px;
                margin-top: 10px;
                background-color: #000;
            }
        }
    }
    .tags {
        display: flex;
        gap: 10px;
        padding: 10px 15px;
        .tag {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            padding-left: 9px;
            padding-right: 11px;
            color: #1B1A1E;
            font-size: 14px;
            line-height: 20px;
            border: 1px solid #F1F1F5;
            background-color: #F1F1F5;
            box-sizing: border-box;
            border-radius: 20px;
            &.active {
                color: #665BD4;
                border-color: transparent;
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                background-image:
                    linear-gradient(90deg, #fff, #fff),
                    linear-gradient(135deg, #5B3CF9, #9334EA, #F13DB7);
            }
        }
    }
    .footer-input {
        position: relative;
        &-inner {
            width: 100%;
            height: 36px;
            color: #1B1A1E;
            font-size: 13px;
            padding-left: 12px;
            padding-right: 36px;
            background-color: #F1F1F5;
            border: 1px solid #E9E8EF;
            box-sizing: border-box;
            border-radius: 36px;
        }
        &-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 2px;
            width: 32px;
            height: 32px;
            margin: auto 0;
            background-image: url($oss_host + '/icon/icon-send.png');
            @include iconMixin();
        }
    }
}